@use '@angular/material' as mat;
@mixin filter-bar-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .zpx-filters-bar {
    background-color: get-mat-color($background, card);
    margin-bottom: 1em;
    padding: .5em;
  }
  .zui-filters-card {
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-items: flex-end;
  }
  button.mobile-view {
    display: flex;
    margin: 0px 5% 0px auto;
  }
  .zpx-filters-bar.mobile-view {
    h3 {
      padding-left: 5%;
    }
    zui-filters-bar {
      .zui-filters-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 0px;
        margin-bottom: 2rem;
        border-bottom: 1px solid $steel;
        .filters-group {
          justify-content: center;
          app-dropdown {
            width: 90%;
            mat-form-field {
              width: 100%;
            }
          }
        }
        .button-group {
          margin-left: auto;
          padding: 10% 5%;
        }
      }
    }
  }
}

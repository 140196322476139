@use '@angular/material' as mat;
@mixin event-details-modal-theme($theme) {

  .container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .details-tab {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-group {
    flex: 1;
    width: 30%;
    height: 234px;
    line-height: 16px;
    padding-right: 10px;
  }
  .card {
    margin-left: 10px;
  }

  .card-top {
    height: 96px;
    margin-bottom: 10px;
  }

  .card-bottom {
      height: 64px;
  }

  .map {
    flex-grow: 1;
    width: 395px;
    height: 234px;
    padding-right: 10px;
  }

  table {
    width: 100%;
    .mat-header-cell {
      color: map-get($zonar-gray, default);
      text-transform: uppercase;
      padding: 0.5rem 1rem;
      font:
        600 1rem / 1.375 Open Sans,
        sans-serif;
    }
  }

  .top-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding-bottom: 10px;
  }

  .bottom-table {
    margin-top: 20px;
    width: 100%;
  }

  .exit-button {
    justify-content: flex-end;
    text-align: right;
    display: grid;
  }

  .dialog-actions{
      justify-content: flex-end;

  }
}
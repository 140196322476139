@use '@angular/material' as mat;
@mixin app-component-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .zui-table-container {
        background-color: get-mat-color($background, card);
        padding: 1em;
    }

    #events-table {
        border-radius: 3px;
    }

    .accent-button {
        background-color: mat.get-color-from-palette($accent, default);
        color: get-mat-color($background, card);
        font-weight: bold;
    }

    .white-button {
        background-color: get-mat-color($background, card);
        color:  mat.get-color-from-palette($accent, default);
        font-weight: bold;
    }

    #zpass-container .mat-cell.cdk-column-edit span, #zpass-container .mat-cell.cdk-column-chevron_right span {
        color:  mat.get-color-from-palette($accent, default);
        cursor: pointer;
    }

    a {
        color:  mat.get-color-from-palette($accent, default);
        text-decoration: underline;
        cursor: pointer;
    }


    .mat-tab-body-wrapper {
        background-color: get-mat-color($background, card);
        box-shadow: 0 3px 1px -2px mat.get-color-from-palette($primary, lighter), 0 2px 2px mat.get-color-from-palette($primary, lighter), 0 1px 5px mat.get-color-from-palette($primary, lighter);
        border-radius: 3px;
      }

    .shadow-border {
        box-shadow: 0 3px 1px -2px mat.get-color-from-palette($primary, lighter), 0 2px 2px mat.get-color-from-palette($primary, lighter), 0 1px 5px mat.get-color-from-palette($primary, lighter);
        border-radius: 3px;
    }

}
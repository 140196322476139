@use '@angular/material' as mat;
@import '@zonar-ui/material/zonar-ui-material.theme';
@import '@zonar-ui/core/zonar-ui-core.theme';


@mixin manage-group-modal-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    #manage-group-modal {
        .mat-form-field {
            display: block;
        }
    
        .mat-form-field-appearance-outline .mat-form-field-wrapper {
            margin: 0 0;
        }
    
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }
    

        .first-column ul li {
            padding: .5em;
        }
        
        li {
            cursor: pointer;
            padding-top: .5em;
            padding-bottom: .5em;
        }
    
    
        .group-chechbox:checked ~ label div {
            background-color: $hightlight-blue;
        }
    
    
        .inactive-groups-label {
            display: inline-block;
            margin-right: .5em;
        }

        .group-list {
            height: 16em;
            overflow: scroll;
        }

        .second-column {
            margin-bottom: 1em;
            width: 20em;
        }

        .second-column header {
            font-size: 20px;
            padding: 1em
        }


        .second-column ul {
            height: 24em;
            overflow: scroll;
            padding: 1em
        }

        .second-column mat-checkbox {
            float: right;
        }

        .second-column li p {
            display: inline-block;
        }



    }
    

    .bottom-padding {
        margin-bottom: 2em;
    }

    .inactive-group {
        background: $ghost;
    }

    .selected-group {
        background-color: $hightlight-blue;
    }

    .button-container {
        margin: auto;
    }
    
}
@use '@angular/material' as mat;
@import '@zonar-ui/material/zonar-ui-material.theme';
@import '@zonar-ui/core/zonar-ui-core.theme';
@import '@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import '@zonar-ui/progress-spinner/zonar-ui-progress-spinner.theme';
@import '@zonar-ui/notifications/zonar-ui-notifications.theme';
@import '@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss';
@import '@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import '@zonar-ui/table/zonar-ui-table.theme';
@import '@zonar-ui/filter/zonar-ui-filter.theme';
@import '@zonar-ui/date-picker/zonar-ui-date-picker.theme';
@import '../app/app.theme';
@import '../app/components/table-passholders-filter-bar/table-passholders-filter-bar.theme';
@import '../app/components/modals/manage-group-modal/manage-group-modal.theme';
@import '../app/components/modals/event-details-modal/event-details-modal.theme';

@mixin apply-zonar-theme($theme) {
  $primary: map-get($theme, primary);

  @include mat.all-component-themes($theme);
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-footer-component-theme($theme);
  @include zonar-ui-progress-spinner-theme($theme);
  @include zonar-ui-notifications-theme($theme);
  @include zonar-ui-sidenav-theme($theme);
  @include zonar-ui-table-theme($theme);
  @include zonar-ui-filter-theme($theme);
  @include zonar-ui-date-picker-theme($theme);
  @include app-component-theme($theme);
  @include filter-bar-theme($theme);
  @include manage-group-modal-theme($theme);
  @include event-details-modal-theme($theme);

  .mat-sidenav-content,
  .content-container {
    background-color: mat.get-color-from-palette($primary, 200);
  }
}

.zonar-default-theme {
  @include apply-zonar-theme($zonar-default-theme);
}

.zonar-dark-theme {
  @include apply-zonar-theme($zonar-default-theme);
  background-color: black;
}

.content-container {
  padding: 2rem;
}

app-passholder-table.mobile-view {
  margin-top: 0px;
}

.hidden {
  display: none;
}

// Fix for the zui-searchable-dropdown, desktop and mobile - the placeholder text should not overlap the search icon
// TODO - Move this into the pattern library
.cdk-overlay-pane {
  .mat-select-search-inner {
    .mat-select-search-input {
      text-indent: 1em;
    }
  }
}
